@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: black;
  background: white
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
